import React from 'react';
import {FaqComponent} from "./Faq.Component";
import {TestimonialComponent} from "./Testimonial.Component";

function Promotion({faqs }) {
    return (
        <div className="w-full relative Tmoney bg-white">
            <div className="w-11/12 lg:w-10/12 flex border-blue-600 gap-4 py-0 mx-auto">
                <div className="bg-white w-full mx-auto " style={{ borderRadius: "20px 0 20px 0" }}>
                    <div id={"faq"} className=" mb-24 w-full">
                        <FaqComponent faqs={faqs} />
                    </div>
                    <div id={"maps promosi"} className="mb-24  w-full">
                        <div className="text-center">
                            <h2 className="fs-title Tmoney text-center text-blue-700 fw-500">Tempat kami</h2>
                        </div>
                        <div className="mt-5 w-full overflow-x-auto scrollbar-hide">
                            <div className="flex gap-4 lg:w-6/12 mx-auto">
                                <div className="scrollbar-hide relative w-full  mx-auto ">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1313.248791627214!2d106.52157822321814!3d-6.237852130226118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e4207005d9675cf%3A0x8c93b3c61c7731b5!2sTK%2FRA%20AR%20RASYID%20CITRA%20RAYA!5e0!3m2!1sen!2sid!4v1710996272993!5m2!1sen!2sid"
                                        className={"radius-8 w-full  "} style={{height:"250px"}}  allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    <div className={"shadow-n w-full h-full absolute top-0"}>
                                        <a target="_blank"  href={"https://www.google.com/maps/place/TK%2FRA+AR+RASYID+CITRA+RAYA/@-6.2380443,106.5221065,20.99z/data=!4m6!3m5!1s0x2e4207005d9675cf:0x8c93b3c61c7731b5!8m2!3d-6.2380333!4d106.522088!16s%2Fg%2F11vwhhq3sr?entry=ttu"}>
                                            <div className={"relative w-full h-full "}>
                                                <div className={" absolute fs-16 px-3 py-1 bottom-5 right-5 bg-blue-600 w-6/12 radius-4 cursor-pointer  text-white "}>
                                                    <h2>Lihat di google maps</h2>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div id={"testimonial"} className="my-0 w-full">*/}
                    {/*    <TestimonialComponent  />*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default Promotion;
