import {Truncate20} from "../../Helper/Truncate";
import React, {useState} from "react";

export const ImgActivityComponent = ({slide}) => {

    const [popUp , setPopUp] = useState(false);
    const showPopUp = () => {
       setPopUp(!popUp)
    }

    return(
        <>
            <div className={"w-11/12 mx-auto shadow"}  style={{borderRadius: "4px "}}>
                <div className={"w-full"} onClick={showPopUp}>
                    <div className={"w-full h-img-card-act"}>
                        <img className={"w-full h-full object-cover"} style={{borderRadius: "4px 4px  0 0"}} src={slide?.image}  />
                    </div>
                    <div className={"w-full py-3"}>
                        <div className={"w-11/12 mx-auto"}>
                            <h2 className={"fs-16 fw-500"}>{Truncate20(slide?.title)}</h2>
                        </div>
                    </div>
                </div>
                {popUp &&(
                    <div className="fixed inset-0 z-50 flex items-center justify-center" onClick={showPopUp}>
                        <div className="absolute z-40 inset-0 bg-gray-700 opacity-70" onClick={showPopUp}></div>
                        <div className="relative z-50  bg-white rounded-lg w-11/12 mx-auto">
                            <div style={{height:"280px"}}>
                                <img className={"w-full object-cover  h-full"} style={{ borderRadius:"8px 8px 0 0"}} src={slide?.image}  />
                            </div>
                            <div>
                                <div className={"w-11/12 mx-auto"}>
                                    <div className="pt-3 text-left text-blue-600">
                                        <h2 className="fs-28 font-semibold">Misi baru</h2>
                                    </div>
                                    <div className="pb-3 text-left text-gray-400">
                                        <h2 className="fs-18 fw-500">Misi baru</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export const CardActivityComponent = ({name , description , image}) => {
    return(
        <>
            <div className={"w-11/12 mx-auto shadow"}  style={{borderRadius: "4px "}}>
                <div className={"w-full"}>
                    <div className={"w-full h-img-card-act"}>
                        <img className={"w-full h-full object-cover"} style={{borderRadius: "4px 4px  0 0"}} src={image}  />
                    </div>
                    <div className={"w-full py-3"}>
                        <div className={"w-11/12 mx-auto"}>
                            <h2 className={"fs-16 fw-500"}>{Truncate20(name)}</h2>
                            <p className={"fs-15"}>{Truncate20(description)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const CardActivityDashboardComponent = ({slide}) => {
    return(
        <>
            <div className={"w-11/12 mx-auto shadow"}  style={{borderRadius: "4px "}}>
                <div className={"w-full"}>
                    <div className={"w-full h-img-card-act"}>
                        <img className={"w-full h-full object-cover"} style={{borderRadius: "4px 4px  0 0"}} src={slide?.image}  />
                    </div>
                    <div className={"w-full py-3"}>
                        <div className={"w-11/12 mx-auto"}>
                            <h2 className={"fs-16 fw-500"}>{Truncate20(slide?.title)}</h2>
                            <p className={"fs-15"}>{Truncate20(slide?.paragraph)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}