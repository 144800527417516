import { BannerRegisteredComponent } from "../Component/BannerRegistered.Component";
import React, { useState } from "react";
import Promotion from "../Component/Promotion";
import {NavbarComponent} from "../Component/Navbar.Component";

function Registration({school }) {
    const [dataValue, setDataValue] = useState('TK');

    return (
        <>
            <NavbarComponent />
            <div className={"w-full"}>
                <BannerRegisteredComponent />
                {/*<div className={"w-11/12 mx-auto"}>*/}
                {/*    <div className={"text-center mt-5"}>*/}
                {/*        <h2 className={"fs-28 fw-500 text-gray-700"}>Syarat Dan Ketentuan Pnedaftaran</h2>*/}
                {/*        <h2 className={"fs-18 fw-400 text-gray-600"}>Penerimaaan Peserta Didik Baru (PPDB) PAUD, TK/RA AR-RASYID</h2>*/}
                {/*        <h2 className={"fs-20 fw-400 text-gray-600"}>Tahun Pelajaran 2024-2025</h2>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={"w-11/12 mx-auto"}>
                            <div className={"text-center mt-10"}>
                                <h2 className={"fs-20 fw-500 text-gray-700"}>Hubungi Admin</h2>
                            </div>
                            <div className={"w-full py-2 lg:py-8"}>
                                <div className={"w-11/12 lg:w-8/12 xl:w-6/12 mx-auto "}>
                                    <p className={"fs-13"}>Pilih kelas</p>
                                </div>
                                <div className={"w-11/12 lg:w-8/12 xl:w-6/12 mx-auto "}>
                                    <div className={"relative"}>
                                        <select
                                            className={"w-full fs-14 cursor-pointer py-3 border border-gray-100"}
                                            value={dataValue}
                                            onChange={(e) => setDataValue(e.target.value)}>
                                            <option className={"fs-14 text-gray-500 cursor-pointer"}>PAUD</option>
                                            <option className={"fs-14 text-gray-500 cursor-pointer"}>TK</option>
                                            <option className={"fs-14 text-gray-500 cursor-pointer"}>BIMBEL</option>
                                            <option className={"fs-14 text-gray-500 cursor-pointer"}>BTQ</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={"w-11/12 lg:w-8/12 xl:w-6/12 mx-auto "}>
                                    {dataValue === "TK" ? (
                                        <div>
                                            <div className={"mt-3"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Rincian Pendaftaran TK</h2>
                                                <div className={"border-b border-gray-200 pb-6"}>
                                                    <div>
                                                        <div className={"border border-gray-300 "}>
                                                            <div className={"border-b py-3 border-gray-300"}>
                                                                <div className={"w-11/12 mx-auto"}>
                                                                    <h2 className={"fs-18 text-gray-500 fw-500"}>BIAYA TK Rp.2.500.000</h2>
                                                                </div>
                                                            </div>
                                                            <div className={"w-11/12 py-4 mx-auto"}>
                                                                <div>
                                                                    <p className={"text-gray-500 fs-16"}>Include : </p>
                                                                    <div className={"w-11/12 mx-auto"}>
                                                                        <ul className={"list-disc"}>
                                                                            <li className={"fs-17 my-2 text-gray-500"}>
                                                                                Sarana prasarana
                                                                            </li>
                                                                            <li className={"fs-17 my-2 text-gray-500"}>
                                                                                Spp bulan Juli (Rp 200.000/bln)
                                                                            </li>
                                                                            <li className={"fs-17 my-2 text-gray-500"}>
                                                                                Seragam 5 stel
                                                                            </li>
                                                                            <li className={"fs-17 my-2 text-gray-500"}>
                                                                                Tas Sekolah
                                                                            </li>
                                                                            <li className={"fs-17 my-2 text-gray-500"}>
                                                                                Buku paket 1 tahun
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"my-0 border-b border-gray-200 pb-6"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Ketentuan dan Persyaratan</h2>
                                                <div>
                                                    <div className={""}>
                                                        <div className={"w-11/12  mx-auto"}>
                                                            <div>
                                                                <div className={"w-full mx-auto"}>
                                                                    <ul className={" list-decimal"}>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Masa Pendaftaran : Bulan Febuari - Juni 2024
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Melakukan Pendaftaran dan membayar uang muka/DP sejumlah Rp.1.000.000,- (satu juta tupiah)
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Memawa foto copy KK & AKTA LAHIR
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(dataValue === "PAUD") ? (
                                        <div>
                                            <div className={"mt-3"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Rincian Pendaftaran PAUD</h2>
                                                <div className={"border-b border-gray-200 pb-6"}>
                                                    <div>
                                                        <div className={"border border-gray-300 "}>
                                                            <div className={"border-b py-3 border-gray-300"}>
                                                                <div className={"w-11/12 mx-auto"}>
                                                                    <h2 className={"fs-18 text-gray-500 fw-500"}>BIAYA TK Rp.1.750.000</h2>
                                                                </div>
                                                            </div>
                                                            <div className={"w-11/12 py-4 mx-auto"}>
                                                                <div>
                                                                    <p className={"text-gray-500 fs-16"}>Include : </p>
                                                                    <div className={"w-11/12 mx-auto"}>
                                                                        <ul className={"list-disc"}>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Sarana prasarana
                                                                            </li>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Spp bulan Juli (Rp 150.000/bln)
                                                                            </li>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Seragam 3 stel
                                                                            </li>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Tas Sekolah
                                                                            </li>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Buku paket 1 tahun
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"my-0 border-b border-gray-200 pb-6"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Ketentuan dan Persyaratan</h2>
                                                <div>
                                                    <div className={""}>
                                                        <div className={"w-11/12  mx-auto"}>
                                                            <div>
                                                                <div className={"w-full mx-auto"}>
                                                                    <ul className={" list-decimal"}>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Masa Pendaftaran : Bulan Febuari - Juni 2024
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Melakukan Pendaftaran dan membayar uang muka/DP sejumlah Rp.1.000.000,- (satu juta tupiah)
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Memawa foto copy KK & AKTA LAHIR
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(dataValue === "BTQ") ? (
                                        <div>
                                            <div className={"mt-3"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Rincian Pendaftaran PAUD</h2>
                                                <div className={"border-b border-gray-200 pb-6"}>
                                                    <div>
                                                        <div className={"border border-gray-300 "}>
                                                            <div className={"border-b py-3 border-gray-300"}>
                                                                <div className={"w-11/12 mx-auto"}>
                                                                    <h2 className={"fs-18 text-gray-500 fw-500"}>BIAYA Pendaftaran Rp.200.000</h2>
                                                                </div>
                                                            </div>
                                                            <div className={"w-11/12 py-4 mx-auto"}>
                                                                <div>
                                                                    <p className={"text-gray-500 fs-16"}>Include : </p>
                                                                    <div className={"w-11/12 mx-auto"}>
                                                                        <ul className={"list-disc"}>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Sarana prasarana
                                                                            </li>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Spp bulanan (Rp.200.000/bln)
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"my-0 border-b border-gray-200 pb-6"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Ketentuan dan Persyaratan</h2>
                                                <div>
                                                    <div className={""}>
                                                        <div className={"w-11/12  mx-auto"}>
                                                            <div>
                                                                <div className={"w-full mx-auto"}>
                                                                    <ul className={" list-decimal"}>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Beragama muslim
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Melakukan Pendaftaran dan membayar uang muka/DP sejumlah Rp.200.000,- (dua ratus ribu rupiah)
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Memawa foto copy KK & AKTA LAHIR
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(dataValue === "BIMBEL") ? (
                                        <div>
                                            <div className={"mt-3"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Rincian Pendaftaran BIMBEL</h2>
                                                <div className={"border-b border-gray-200 pb-6"}>
                                                    <div>
                                                        <div className={"border border-gray-300 "}>
                                                            <div className={"border-b py-3 border-gray-300"}>
                                                                <div className={"w-11/12 mx-auto"}>
                                                                    <h2 className={"fs-18 text-gray-500 fw-500"}>BIAYA PENDAFTARAN Rp.200.000</h2>
                                                                </div>
                                                            </div>
                                                            <div className={"w-11/12 py-4 mx-auto"}>
                                                                <div>
                                                                    <p className={"text-gray-500 fs-16"}>Include : </p>
                                                                    <div className={"w-11/12 mx-auto"}>
                                                                        <ul className={"list-disc"}>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Sarana prasarana
                                                                            </li>
                                                                            <li className={"fs-18 text-gray-500"}>
                                                                                Spp bulanan (Rp.200.000/bln)
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"my-0 border-b border-gray-200 pb-6"}>
                                                <h2 className={"fs-20 fw-400 py-3 text-gray-600"}>Ketentuan dan Persyaratan</h2>
                                                <div>
                                                    <div className={""}>
                                                        <div className={"w-11/12  mx-auto"}>
                                                            <div>
                                                                <div className={"w-full mx-auto"}>
                                                                    <ul className={" list-decimal"}>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Melakukan Pendaftaran dan membayar uang muka/DP sejumlah Rp.200.000,- (dua ratus ribu rupiah)
                                                                        </li>
                                                                        <li className={"fs-17 text-gray-500"}>
                                                                            Memawa foto copy KK & AKTA LAHIR
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <>
                                        </>
                                    )}
                                </div>
                                <div className={"my-10 w-full text-center items-center"}>
                                    <div className={"text-center my-4 items-center"}>
                                        <h2 className={"fs-title  text-center items-center text-blue-700 fw-500"}>Ayo Daftar sekarang!!</h2>
                                    </div>
                                    <div className={"my-3 lg:my-1 text-center items-center"}>
                                        <a href={`https://wa.me/+6287771200907?text=Assalamualaikum RA AR-Rasyid, saya ingin bertanya PPDB ${dataValue}`} target="_blank" className={"bg-blue-500 hover:bg-blue-600 text-center items-center fs-17 text-white py-2 px-4 lg:py-3 lg:px-6 cursor-pointer"} style={{ borderRadius:"8px 0 8px 0 "}}>
                                            Hubungin Kontak ini
                                        </a>
                                    </div>
                                    <div className={"my-10"}>
                                        <hr className={" w-full mx-auto py-0.25 radius-8"} style={{background:"#f3f3f3"}}></hr>
                                    </div>
                                </div>

                            </div>
                        </div>
            </div>
            <Promotion linkAddress={school?.link_address} faqs={school?.faqs}/>

        </>
    )
}

export default Registration;
