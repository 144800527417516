
export const TeacherCardComponent = ({ image, name, position }) => {

    return (
        <div className={"w-card mx-auto"}>
            <div className={" my-4 bg-white radius-8 pt-8   relative"}>
                <div className={"card-teacher shadow z-40"}>
                    <img
                        className={"w-full h-full radius-8 object-cover"}
                        src={`${image}`}
                        alt="Teacher Image"
                    />
                </div>
                <div className={"my-4 text-center bg-white radius-8 pt-6 pb-2  relative"}>
                    <h2 className={"fs-17 "}>{name}</h2>
                    <p className={"fs-15"}>{position}</p>
                </div>
            </div>
        </div>
    );
};
