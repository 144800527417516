import React from "react";

export const TiktokCardComponent = ({link}) => {
    return(
        <>
            <div className="scrollbar-hide w-full  mx-auto ">
                <iframe
                    src={link}
                    width="320"
                    className="radius-8 w-full mx-auto scrollbar-hide"
                    height="720"
                    frameBorder="0"
                    allowFullScreen
                    title="TikTok video"
                ></iframe>
            </div>
        </>
    )
}
