export const CardSchoolComponent = ({slide}) => {
    return(
        <>
            <div className={"w-11/12 mx-auto"}>
                <div className={"w-full"}>
                    <div className={"w-full relative h-img-card-school"}>
                        <img className={"w-full h-full object-cover"} style={{borderRadius: "12px 12px  0 12px"}} src={slide?.image}  />
                        <div className={"shadow-n  relative radius-8"}>
                            <div className={"w-full bottom-5 absolute"}>
                                <div className={"w-full py-3 "}>
                                    <div className={"w-11/12   mx-auto"}>
                                        <h2 className={"fs-20 fw-500 text-white"}>{slide?.title}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}