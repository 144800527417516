import {BannerComponent} from "../Component/Banner.Component";
import {VisiMisiComponent} from "../Component/VisiMisi.Component";
import {ActivityComponent} from "../Component/Activity.Component";
import {DetailSchoolComponent} from "../Component/DetailSchool.Component";
import {TeachersComponent} from "../Component/Teachers.Component";
import Promotion from "../Component/Promotion";
import React from "react";
import {NavbarComponent} from "../Component/Navbar.Component";

function Home({school}){


    const activities = [
        {
            "name": "Ibadah ( praktek solat Dhuha setiap hari)\n",
            "description": "Murid TK sedang melakukan Ibadah ( praktek solat Dhuha setiap hari)\n",
            "image": "/assets/image/activities/activity-solat.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Mengaji & Muroja'ah\n",
            "description": "Murid TK sedang melakukan Mengaji & Muroja'ah\n",
            "image": "/assets/image/activities/murojaah.png",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": " Setoran Hapalan Surat pendek\n\n",
            "description": "Murid TK sedang melakukan  Setoran Hapalan Surat pendek\n",
            "image": "/assets/image/activities/btq.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Olahraga \n\n",
            "description": "Murid TK sedang melakukan Olahraga \n",
            "image": "/assets/image/activities/activity-olahraga.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Kreativitas\n\n",
            "description": "Murid TK sedang melakukan Kreativitas\n",
            "image": "/assets/image/activities/activity-creativitas.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Eksperimen \n\n",
            "description": "Murid TK sedang melakukan Eksperimen\n",
            "image": "/assets/image/activities/activity-3.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Calistung\n \n\n",
            "description": "Murid TK sedang melakukan Membaca tulis dan hitung\n",
            "image": "/assets/image/activities/kuis.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },

    ]



    return(
        <>
            <NavbarComponent />
            <div className={"w-full"}>
                <BannerComponent name={school?.name} description={school?.description} address={school?.address} email={school?.email} contact={school?.phone}/>
                <VisiMisiComponent  vision={school?.vision}
                                    direction={school?.directions}
                                    mission={school?.mission} />
                <div className={"w-full py-4"}>
                    <div className={"my-4 text-center"}>
                        <h2 className={"fs-title fw-500 text-blue-600"}>Galeri Aktifitas</h2>
                        <p className={" fs-17 text-gray-500"}>Menampilkan Aktifitas harian yang biasanya dilakukan, maupun event penting</p>
                    </div>
                </div>
                <div className={"w-11/12 lg:w-10/12 mx-auto"}>
                    <ActivityComponent data={activities} />
                </div>
                <TeachersComponent teachers={school?.teachers}/>
                <DetailSchoolComponent />
            </div>
            <Promotion linkAddress={school?.link_address} faqs={school?.faqs}/>
        </>
    )
}

export default Home