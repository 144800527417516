import {VisiMisiComponent} from "../Component/VisiMisi.Component";
import Promotion from "../Component/Promotion";
import React from "react";
import {BannerAboutComponent} from "../Component/BannerAbout.Component";
import {VisiMissionAboutComponent} from "../Component/VisiMissionAbout.Component";
import {NavbarComponent} from "../Component/Navbar.Component";
import {DetailSchoolComponent} from "../Component/DetailSchool.Component";

function About({school }){
    return(
        <>
            <NavbarComponent />
            <div className={"w-full"}>
                <BannerAboutComponent name={school?.name} description={school?.description} address={school?.address} email={school?.email} contact={school?.phone} />
                <VisiMissionAboutComponent  vision={school?.vision}
                                    direction={school?.directions}
                                    mission={school?.mission} />
            </div>

            <div className={"mt-4 mb-4 py-10 w-full text-center items-center"}>
                <div className={"text-center my-4 items-center"}>
                    <h2 className={"fs-title  text-center items-center text-blue-700 fw-500"}>Ayo Daftar sekarang!!</h2>
                </div>
                <div className={"my-3 lg:my-1 text-center items-center"}>
                    <a href={`https://wa.me/+6287771200907`} target="_blank" className={"bg-blue-500 hover:bg-blue-600 text-center items-center fs-17 text-white py-2 px-4 lg:py-3 lg:px-6 cursor-pointer"} style={{ borderRadius:"8px 0 8px 0 "}}>
                        Hubungin Kontak ini
                    </a>
                </div>

            </div>
            <DetailSchoolComponent />

            <div>
                <Promotion linkAddress={school?.link_address} faqs={school?.faqs}/>
            </div>
        </>
    )
}

export default About