import React, { useState } from 'react';
import { SlideComponent } from './Card/Slide.Component';

export const BannerComponent = ({ name, description, address, contact, email, externalImage }) => {
    const slides = [
        {
            image: '/assets/img/school.jpg',
            title: 'Berkenalan dengan TK/RA AR-Rasyid',
            paragraph: 'Merupakan sekolah kanak-kanak yang menerapkan pendidikan islam yang mengedepankan akhlak yang mulia.',
        },
        {
            image: '/assets/img/harib.jpg',
            title: 'PPDB tahun 2024/2025 telah dibuka',
            paragraph: 'Umi&Abi ayo daftakan anak-anak tercinta ke TK/RA AR-RASYID',
        },
        {
            image: '/assets/image/activities/activity-2.jpg',
            title: 'Pesantren kilat TK/RA AR-RASYID.',
            paragraph: 'TK/RA AR-RASYID mengadakan Pesantren kilat yang diikuti oleh semua jenjang di AR-RASYID',
        },
    ];

    const [isDropdown, setIsDropdown] = useState(false);

    const handleIsDropdown = () => {
        setIsDropdown(!isDropdown);
    };

    return (
        <div className="w-full bg-white pt-20 lg:pt-10">
            <div className="w-11/12 lg:w-10/12 mx-auto">
                <SlideComponent slides={slides} />
                <div className="bg-white py-3 border-t border-gray-200">
                    <h1 className="w-full righteous md:pt-6 pt-5 pb-0 text-blue-600 fs-28 cursor-pointer" onClick={handleIsDropdown}>
                        Sejarah singkat {name}
                    </h1>
                    <div className={`my-2 ${isDropdown ? 'open' : 'closed'}`}>
                        <p className="fs-17 text-gray-500">
                            Ar Rasyid didirikan pada bulan Oktober 2023 mulai dari PAUD, TK/RA sebagai bentuk kepedulian kami terhadap anak usia dini yang saat ini banyak mengalami dampak negatif dr seringnya bermain gadget (Hp) seperti speech delay, sering tantrum, kurang konsentrasi,dsb.
                            <br/><br/>
                            Oleh sebab itu Ar Rasyid hadir ditengah-tengah masyarakat khususnya wilayah Citra Raya Cikupa dan sekitarnya Sebagai Solusi untuk orangtua yang bingung mencari Sekolah anak usia dini yg berbasis Islami, memprioritaskan perkembangan nilai agama dan moral, perkembangan fisik-motorik, kognitif, bahasa, sosial emosional, dan perkembangan seni kreativitas anak.
                            <br/><br/>
                            Disamping itu, TK/RA AR-RASYID juga merupakan sekolah dengan Harga yang sangat Terjangkau namun Tetap memprioritaskan Kualitas.
                        </p>
                    </div>
                    <p className="poppins text-gray-500 fs-17 my-3">
                        <b>Alamat</b> : {address}
                    </p>
                    <p className="poppins text-gray-500 fs-17 my-1">
                        <b>Kontak</b> : {contact}
                    </p>
                    <p className="poppins text-gray-500 fs-17 my-1">
                        <b>Email</b> : {email}
                    </p>
                </div>
            </div>
        </div>
    );
};
