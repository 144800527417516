export const VisiMisiComponent = ({vision , mission , direction}) => {
    return(
        <div style={{ position: "relative" , background:"#ffffff"}}>
            <div >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#3C9EFF" fill-opacity="1" d="M0,224L120,229.3C240,235,480,245,720,261.3C960,277,1200,299,1320,309.3L1440,320L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            </div>
            <div className={"w-full relative  Tmoney  flex-wrap "} style={{ background:"#3c9eff"}}>
                <div className={"w-11/12 lg:w-10/12 block lg:flex   pb-16  z-40 gap-4 pt-16  mx-auto"}>
                    <div className={"bg-white w-full my-2 md:py-4  md:px-6 lg:my-0 lg:w-4/12  px-2 py-2 lg:px-5 lg:py-4 radius-8"} >
                        <div className={" w-full "}>
                            <div className={""}>
                                <h2 className={"fs-28 Tmoney text-blue-700 fw-500"}>Visi</h2>
                            </div>
                            <>
                                {vision?.map((item) => {
                                    return(
                                        <div className={"my-1"}>
                                            <p className={"fs-16 Tmoney  text-gray-600"}>
                                                {item?.description}
                                            </p>
                                        </div>
                                    )
                                })}
                            </>
                        </div>
                    </div>
                    <div className={"bg-white w-full my-2 md:py-4  md:px-6 lg:my-0 lg:w-4/12  px-2 py-2 lg:px-5 lg:py-4 radius-8"} >
                        <div className={""}>
                            <div className={""}>
                                <h2 className={"fs-28 Tmoney text-blue-700 fw-500"}>Misi</h2>
                            </div>
                            <div className={""}>
                                <ul className={"list-disc pl-6"}>
                                    {mission?.map((item) => {
                                        return(
                                            <li>
                                                <div className={"my-1"}>
                                                    <p className={"fs-16 Tmoney  text-gray-600"}>
                                                        {item?.description}
                                                    </p>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"bg-white w-full my-2 md:py-4  md:px-6 lg:my-0 lg:w-4/12  px-2 py-2 lg:px-5 lg:py-4 radius-8"} >
                        <div className={""}>
                            <div className={""}>
                                <h2 className={"fs-28 Tmoney text-blue-700 fw-500"}>Tujuan</h2>
                            </div>
                            <div className={""}>
                                <ul className={"list-disc pl-6"}>
                                    {direction?.map((item) => {
                                        return(
                                            <li>
                                                <div className={"my-1"}>
                                                    <p className={"fs-16 Tmoney  text-gray-600"}>
                                                        {item?.description}
                                                    </p>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={" relative"}>
                    <div className={"bg-img-cloud"}>
                        <img className={"mx-auto"} src={"/assets/img/cloud-img.svg"} />
                    </div>
                </div>
            </div>
        </div>
    )
}
