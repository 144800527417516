import {TeacherCardComponent} from "./Card/TeacherCard.Component";

export const TeachersComponent = ({teachers}) => {
    return(
        <div className={""} style={{ position: "relative" }}>
            <div className={"w-full relative  Tmoney  "} style={{ background:"#3c9eff"}}>
                <div className={"bg-img-cloud-rev "}>
                    <img className={"mx-auto"} src={"/assets/img/cloud-img-rev.svg"} />
                </div>
                <div className={"w-11/12 md:w-10/12 xl:w-10/12 block  z-40 gap-4 pt-10 lg:pt-20  mx-auto"} >
                    <div className={"w-full pb-7"}>
                        <div className={"my-4 w-10/12 mx-auto lg:w-full text-center"}>
                            <h2 className={"Tmoney fs-title text-white"}>Hai semua kenalan yuk dengan guru guru kami</h2>
                        </div>
                    </div>
                    <div className={"w-full"}>
                        <div className={"w-full"}>
                            <ul className={"w-full sm:w-10/12 mx-auto  md:w-8/12 lg:w-9/12 xl:w-8/12 pt-0 lg:pt-6  flex-wrap flex"}>
                                {teachers?.map((item , index) => {
                                    return(
                                        <>
                                            <li className={" w-6/12  lg:w-4/12 pt-14 lg:pt-10   "} key={index}>
                                                <TeacherCardComponent image={item?.image} name={item?.name} position={item?.position}/>
                                            </li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200"><path fill="#3C9EFF" fill-opacity="1" d="M0,128L120,122.7C240,117,480,107,720,101.3C960,96,1200,96,1320,96L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
        </div>
    )
}