import React, {useState} from "react";
import {FAQCardComponent} from "./Card/FAQCard.Component";

export const FaqComponent = ({faqs}) => {
    const [popUpDropdown, setPopUpDropdown] = useState(false);

    const onChangePopUpDropdown = (id) => {
        setPopUpDropdown(prevState => ({
            ...prevState,
            [id] : !prevState[id]
        }));
    }

    return(
        <>
            <div className={" py-4"} >
                <div className={"w-full my-3 "}>
                    <div className={"text-center"}>
                        <h2 className="fs-title Tmoney text-center text-blue-700 fw-500">Pertanyaan Umum</h2>
                    </div>
                    <ul className={"w-full my-5"}>
                        {faqs?.map((item , index) => {
                            return(
                                <li className={"my-5"} key={index}>
                                    <div onClick={() => onChangePopUpDropdown(item?.id)}>
                                        <FAQCardComponent id={item?.id} title={item?.question} value={item?.answer}  popUpDropdown={popUpDropdown}/>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>

        </>
    )
}