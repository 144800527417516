
export const BannerRegisteredComponent = () => {
    return(
        <>
            <div className="w-full pt-16 lg:pt-0 pb-4" style={{ minWidth:"300px"}}>
                <div className="w-full py-10 sm:py-20 " style={{ background:"#F7F9FA"}}>
                    <div className="w-11/12 md:w-10/12 mx-auto">
                        <div className={"text-center"}>
                            <h2 className="w-full fw-500  md:pt-6 pt-5 pb-0 text-blue-600 fs-title">
                                PPDB PAUD, TK, BTQ, BIMBEL
                            </h2>
                            <h2 className="w-full fw-500   pb-0 text-blue-600 fs-28">
                                Telah Dibuka Tahun Ajaran 2024-2025
                            </h2>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}