import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NavbarComponent = () => {
    const location = useLocation();
    const pathname = location.pathname.split('/').pop();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleFalseMenu = () => {
        setIsMenuOpen(false);
    };

    const menuIcon = isMenuOpen ? faTimes : faBars;

    return (
        <nav className="w-full fixed top-0 left-0 bg-white z-50">
            <header className="w-full border-b border-gray-200 py-4 lg:py-4">
                <div className="w-11/12 lg:w-10/12 justify-between flex mx-auto" style={{ maxWidth: "1400px" }}>
                    <div className="lg:w-4/12">
                        <div className="h-logo">
                            <img className="h-full object-cover" src="/assets/img/logo.svg" alt="logo" />
                        </div>
                    </div>
                    <div className="w-6/12 hidden lg:flex my-auto">
                        <div className="w-full my-auto">
                            <ul className="my-auto flex justify-end gap-8">
                                <li className={`my-auto mx-auto ${pathname === "" ? "text-blue-600" : "text-gray-500"}`}>
                                    <Link to="/" >Home</Link>
                                </li>
                                <li className={`my-auto mx-auto ${pathname === "aktivitas" ? "text-blue-600" : "text-gray-500"}`}>
                                    <Link to="/aktivitas">Aktivitas</Link>
                                </li>
                                <li className={`my-auto mx-auto ${pathname === "pendaftaran" ? "text-blue-600" : "text-gray-500"}`}>
                                    <Link to="/pendaftaran">Pendaftaran</Link>
                                </li>
                                <li className={`my-auto mx-auto ${pathname === "tentang-kami" ? "text-blue-600" : "text-gray-500"}`}>
                                    <Link to="/tentang-kami">Tentang Kami</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="my-auto lg:hidden">
                        <button className="block lg:hidden text-blue-700 items-end justify-end" style={{ fontSize: "19px" }} onClick={toggleMenu}>
                            <FontAwesomeIcon icon={menuIcon} />
                        </button>
                        {isMenuOpen && <PhoneMenuComponent pathname={pathname} handleFalseMenu={handleFalseMenu} />}
                    </div>
                </div>
            </header>
        </nav>
    );
};

const PhoneMenuComponent = ({ pathname, handleFalseMenu }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="fixed top-16 bg-white left-0 w-full">
            <ul className="w-10/12 mx-auto text-center pt-8 pb-12 my-auto justify-center gap-8">
                <li style={{ fontSize: "17px" }} className={`my-auto py-4 mx-auto ${pathname === "" ? "text-blue-600" : "text-gray-500"}`}>
                    <Link onClick={() => { handleFalseMenu(); scrollToTop(); }} to="/">Home</Link>
                </li>
                <li style={{ fontSize: "17px" }} className={`my-auto py-4 mx-auto ${pathname === "aktivitas" ? "text-blue-600" : "text-gray-500"}`}>
                    <Link onClick={() => { handleFalseMenu(); scrollToTop(); }} to="/aktivitas">Aktivitas</Link>
                </li>
                <li style={{ fontSize: "17px" }} className={`my-auto py-4 mx-auto ${pathname === "pendaftaran" ? "text-blue-600" : "text-gray-500"}`}>
                    <Link onClick={() => { handleFalseMenu(); scrollToTop(); }} to="/pendaftaran">Pendaftaran</Link>
                </li>
                <li style={{ fontSize: "17px" }} className={`my-auto py-4 mx-auto ${pathname === "tentang-kami" ? "text-blue-600" : "text-gray-500"}`}>
                    <Link onClick={() => { handleFalseMenu(); scrollToTop(); }} to="/tentang-kami">Tentang Kami</Link>
                </li>
            </ul>
        </div>
    );
};
