import { CardSchoolComponent } from "./Card/CardSchool.Component";

export const DetailSchoolComponent = () => {
    const slides = [
        {
            image: '/assets/img/class-bg.jpg',
            title: 'Kelas Indoor',
        },
        {
            image: '/assets/img/outdoor-class.jpg',
            title: 'Kelas Outdoor',
        },
        {
            image: '/assets/img/taman-5.jpg',
            title: "Taman Bermain Depan",
        },
        {
            image: '/assets/img/taman-56.png',
            title: "Taman Bermain Belakang",
        },

    ];

    return(
        <div className={"w-full bg-white relative my-0"}>
            <div className={"md:w-11/12 w-full lg:w-10/12 block lg:flex overflow-x-scroll scrollbar-hide mx-auto"}>
                <div className={" w-11/12 mx-auto lg:mx-0 lg:w-3/12"}>
                    <div className={"my-4 text-left"}>
                        <h2 className={" fs-title fw-500 text-blue-600"}>Detail Sekolah</h2>
                        <p className={" fs-17 text-gray-500"}>Semua Detail sekolah dan macam macam</p>
                    </div>
                </div>
                <div className={"w-full lg:w-9/12 overflow-x-scroll scrollbar-hide"}>
                    <div className={"w-full "}>
                        <ul className={"w-full flex flex-wrap"}>
                            {slides.map((item, index) => (
                                <div className={"w-full my-2 lg:w-4/12"}>
                                    <li key={index} className={"w-full"}>
                                        <CardSchoolComponent slide={item} />
                                    </li>
                                </div>
                        ))}

                    </ul>
                </div>
            </div>
        </div>
            <div className={"my-10"}>
                <hr className={" w-10/12 mx-auto py-0.25 radius-8"} style={{background:"#f3f3f3"}}></hr>
            </div>
        </div>
);
};
