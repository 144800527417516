import {ActivityComponent} from "../Component/Activity.Component";
import React, {useState} from "react";
import {NavbarComponent} from "../Component/Navbar.Component";

function Activity({ isOnline , activity}){

    const activitiesTK = [
        {
            "name": "Ibadah ( praktek solat Dhuha setiap hari)\n",
            "description": "Murid TK sedang melakukan Ibadah ( praktek solat Dhuha setiap hari)\n",
            "image": "/assets/image/activities/activity-solat.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Mengaji & Muroja'ah\n",
            "description": "Murid TK sedang melakukan Mengaji & Muroja'ah\n",
            "image": "/assets/image/activities/murojaah.png",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": " Setoran Hapalan Surat pendek\n\n",
            "description": "Murid TK sedang melakukan  Setoran Hapalan Surat pendek\n",
            "image": "/assets/image/activities/btq.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Olahraga \n\n",
            "description": "Murid TK sedang melakukan Olahraga \n",
            "image": "/assets/image/activities/activity-olahraga.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Kreativitas\n\n",
            "description": "Murid TK sedang melakukan Kreativitas\n",
            "image": "/assets/image/activities/activity-creativitas.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Eksperimen \n\n",
            "description": "Murid TK sedang melakukan Eksperimen\n",
            "image": "/assets/image/activities/activity-3.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Calistung\n \n\n",
            "description": "Murid TK sedang melakukan Membaca tulis dan hitung\n",
            "image": "/assets/image/activities/kuis.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },

    ]

    const activitiesPAUD = [
        {
            "name": "Ibadah ( praktek solat Dhuha setiap hari)\n",
            "description": "Murid PAUD sedang melakukan Ibadah ( praktek solat Dhuha setiap hari)\n",
            "image": "/assets/image/activities/activity-solat.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Experiment\n",
            "description": "Murid PAUD sedang melakukan Ibadah ( praktek solat Dhuha setiap hari)\n",
            "image": "/assets/image/paud/paud5.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },
        {
            "name": "Kegiatan literasi \n",
            "description": "Murid PAUD sedang melakukan Ibadah ( praktek solat Dhuha setiap hari)\n",
            "image": "/assets/image/paud/paud1.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },

        {
            "name": "Melukis",
            "description": "Murid PAUD sedang melakukan Menggambar\n",
            "image": "/assets/image/paud/paud2.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },

        {
            "name": "Makan siang bersama\n",
            "description": "Murid PAUD sedang melakukan Makan siang bersama\n",
            "image": "/assets/image/paud/paud3.jpg",
            "schoolId": 1,
            "gradeId": 1,
            "level": "tk",
            "createdAt": "2024-03-25 09:54:31",
            "updatedAt": "2024-03-26 17:03:07"
        },


    ]


    const activitiesBTQ = []

    const activitiesBIMBEL = []



    const param1 = "tk"
    const param2 = "paud"
    const param3 = "bimbel"
    const param4 = "BTQ"
    const [activeTab, setActiveTab] = useState( 'tk');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const [popUpDropdown, setPopUpDropdown] = useState(false);

    const onChangePopUpDropdown = (id) => {
        setPopUpDropdown(prevState => ({
            ...prevState,
            [id] : !prevState[id]
        }));
    }

    return(
        <>
            <NavbarComponent />
            <div className={"pt-20 lg:pt-10  mx-auto"} style={{minWidth:"300px" ,maxWidth:"1500px"}}>
                <div className={"w-full mx-auto"}>
                    <div className={"w-full md:w-11/12 mx-auto "}>
                        <div className={"my-4 text-center"}>
                            <h2 className={"fs-title fw-500 w-11/12 mx-auto text-blue-600"}>Galeri Aktifitas</h2>
                            <p className={" fs-17 text-gray-500"}>Menampilkan Aktifitas harian yang biasanya dilakukan, maupun event penting</p>
                        </div>
                    </div>
                    <div className={"block md:w-10/12 lg:py-8  mx-auto "}>
                        <div className={"block md:w-10/12 "}>
                            <div className={"w-full pt-4 border-b border-gray-200 pb-3 "}>
                                <div className={"block"}>
                                    <div className={""}>
                                        <div  className={"cursor-pointer w-11/12 mx-auto"} onClick={() => onChangePopUpDropdown(1)}>
                                            <h2 className={"fs-18 fw-500 text-gray-600"}>Program Unggulan</h2>
                                        </div>
                                    </div>
                                    <div className={"w-11/12 mx-auto"}>
                                        <ul className={"mx-4 md:mx-auto list-disc"}>
                                            {popUpDropdown[1] && (
                                                <div>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Pembelajaran Al-Quran, Hadist, Do'a Harian & Asmaul Husna (Bacaan & Hafalan)
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Eskul Tari
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Lukis
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Experimen
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Tapak suci
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Experimen
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        English Club
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Komunikasi itensif antara guru & Orang tua
                                                    </li>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full pt-4 border-b border-gray-200 pb-3"}>
                                <div className={"block"}>
                                    <div  className={"cursor-pointer w-11/12 mx-auto"} onClick={() => onChangePopUpDropdown(2)}>
                                        <h2 className={"fs-18 fw-500 text-gray-600"}>Program Harian</h2>
                                    </div>
                                    <div className={"w-11/12 mx-auto"}>
                                        <ul className={"mx-4 md:mx-auto list-disc"}>
                                            {popUpDropdown[2] && (
                                                <div>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Pembiasaan Ibadah (Sholat Dhuha)
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Pembiasaan Muroja'ah (Membaca Surat Pendek, Do'a Harian, Hadist Nabi, Dzikir Pagi, Asmaul Husna)
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Pemantauan belajar Al-Qur'an & Membaca
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Praktek Wudhu & Infaq setiap hari jum'at
                                                    </li>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"block md:w-10/12 "}>
                            <div className={"w-full pt-4 border-b border-gray-200 pb-3"}>
                                <div className={"block"}>
                                    <div  className={"cursor-pointer w-11/12 mx-auto"} onClick={() => onChangePopUpDropdown(3)}>
                                        <h2 className={"fs-18 fw-500 text-gray-600"}>Program Tahunan</h2>
                                    </div>
                                    <div className={"w-11/12 mx-auto"}>
                                        <ul className={"mx-4 md:mx-auto list-disc"}>
                                            {popUpDropdown[3] && (
                                                <div>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        HUT RI
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Cooking Class
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Manasik Haji
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Market Day
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Renang
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Out Bound
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Personi Ancol
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Berbagi Takjil Gratis
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Maulid Nabi SAW
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Perpisahan (Pentas Seni)
                                                    </li>
                                                    <li className={"fs-16  my-2 text-gray-500"}>
                                                        Santunan Anak Yatim
                                                    </li>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full pt-4 border-b border-gray-200 pb-3"}>
                                <div className={"block"}>
                                    <div className={"cursor-pointer w-11/12 mx-auto"} onClick={() => onChangePopUpDropdown(4)}>
                                        <h2 className={"fs-18 fw-500 text-gray-600"}>Jadwal Kegiatan KBM</h2>
                                    </div>
                                    <div className={"w-11/12 mx-auto"}>
                                        <ul className={"mx-4 md:mx-auto list-disc"}>
                                            {popUpDropdown[4] && (
                                                <div className={"my-3"}>
                                                    <div className={"my-2 "}>
                                                        <h2 className={"fs-17  text-gray-500  fw-500"}>PAUD</h2>
                                                        <div>
                                                            <li className={"fs-16  text-gray-500"}>
                                                                Masuk pukul 07:30 - 10.00
                                                            </li>
                                                            <li className={"fs-16  my-2 text-gray-500"}>
                                                                Setiap Hari Senin, Rabu dan jum'at
                                                            </li>
                                                        </div>
                                                    </div>
                                                    <div className={"my-2 "}>
                                                        <h2 className={"fs-117  text-gray-500  fw-500"}>TK</h2>
                                                        <div>
                                                            <li className={"fs-16   text-gray-500"}>
                                                                Masuk pukul 07:30 - 11.00
                                                            </li>
                                                            <li className={"fs-16  my-2 text-gray-500"}>
                                                                Setiap Hari Senin s/d jum'at
                                                            </li>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"w-full md:w-10/12   mx-auto"} style={{minWidth:"300px" ,maxWidth:"1500px"}}>
                            <div className="pb-2">
                                <ul id="tabs" className="tab-navigation w-11/12 md:w-full m-auto flex border-b border-gray-200 overflow-x-scroll pt-3 pb-3 scrollbar-hide mt-1 text-left px-1 text-purple-600">
                                    <li className="md:px-4  w-11/12 mx-auto text-gray-500 hover:text-purple-600 ">
                                        <a
                                            className={`w-full cursor-pointer  fs-17  ${activeTab === param1 ? 'active text-purple-600' : 'text-gray-500'}`}
                                            onClick={() => handleTabClick( param1 )}
                                        >
                                            TK
                                        </a>
                                    </li>
                                    <li className="md:px-4 ps-0 pe-2 w-full text-gray-500 hover:text-purple-600 ">
                                        <a
                                            id="default-tab"
                                            className={`w-full  cursor-pointer fs-17  ${activeTab === param2 ? 'active text-purple-600' : 'text-gray-500'}`}
                                            onClick={() => handleTabClick(param2)}
                                        >
                                            PAUD
                                        </a>
                                    </li>
                                    <li className="md:px-4 ps-0 pe-2 w-full text-gray-500 hover:text-purple-600 ">
                                        <a
                                            id="default-tab"
                                            className={`w-full  cursor-pointer fs-17  ${activeTab === param3 ? 'active text-purple-600' : 'text-gray-500'}`}
                                            onClick={() => handleTabClick(param3)}
                                        >
                                            BTQ
                                        </a>
                                    </li>
                                    <li className="md:px-4 ps-0 pe-2 w-full text-gray-500 hover:text-purple-600 ">
                                        <a
                                            id="default-tab"
                                            className={`w-full  cursor-pointer fs-17  ${activeTab === param4 ? 'active text-purple-600' : 'text-gray-500'}`}
                                            onClick={() => handleTabClick(param4)}
                                        >
                                            BIMBEL
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={"border-b border-gray-200 mb-10 pb-6"}>
                                <>
                                    {activeTab === param1 && (
                                        <div id={param1} className="w-full">
                                            <div >
                                                <ActivityComponent data={activitiesTK} />
                                            </div>
                                        </div>
                                    )
                                    }
                                    {activeTab === param2 && (
                                        <div>
                                            <div id={param2} className="w-full">
                                                <ActivityComponent data={activitiesPAUD} />
                                            </div>
                                        </div>
                                    )
                                    }
                                    {activeTab === param3 && (
                                        <div>
                                            <div id={param3} className="w-full">
                                                <ActivityComponent name={"BTQ"} data={activitiesBTQ} />
                                            </div>
                                        </div>
                                    )
                                    }
                                    {activeTab === param4 && (
                                        <div>
                                            <div id={param4} className="w-full">
                                                <ActivityComponent name={"Bimbel"} data={activitiesBIMBEL} />
                                            </div>
                                        </div>
                                    )
                                    }
                                </>

                            </div>

                        </div>
            </div>
        </>
    )
}

export default Activity