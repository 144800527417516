
export const FooterComponent = () => {
    return(
        <>
            <footer className={"w-full pb-6 pt-12 bg-gray-50"}>
                <div className={"w-11/12 lg:w-10/12 mx-auto"}>
                    <div className={"w-10/12"}>
                        <div className={""}>
                            <div className={"h-logo"}>
                                <img className={"h-full  object-cover"}  src={"/assets/img/logo.svg"}/>
                            </div>
                            <div className={"my-4 w-full lg:w-8/12 "}>
                                <div className={"py-0.5"}>
                                    <p className={"py-0.5 fs-16 text-gray-500"}>
                                        TK/RA Islami Ar-Rasyid adalah sebuah lembaga pendidikan yang menyediakan layanan untuk anak-anak usia dini.
                                    </p>
                                    <div className={"py-4"}>
                                        <p className={"py-0.5 fs-16 text-gray-500 cursor-pointer hover:text-blue-600"}>
                                            Alamat : Jl. Verdi Barat No.35,Cikupa,Tangerang Regency,Banten 15710
                                        </p>
                                        <p className={"py-0.5  fs-16 text-gray-500 cursor-pointer hover:text-blue-600"}>
                                            Nomor Telepon : +62 877-7120-0907
                                        </p>
                                        <p className={"py-0.5 fs-16 text-gray-500 cursor-pointer hover:text-blue-600"}>
                                            Email :
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={"fs-17 text-gray-500 cursor-pointer "}>
                                <p>Copyright 2024 by @TK-Ar-Rasyid</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}