import React from "react";
import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./Page/Home";
import Activity from "./Page/Activity";
import Registrastion from "./Page/Registrastion";
import About from "./Page/About";
import {TiktokCardComponent} from "./Component/Card/TiktokCard.Component";
import {FooterComponent} from "./Component/Footer.Component";

function App() {


    const school = {
        "school": {
            "id": 1,
            "name": "TK/RA AR-RASYID CITRA RAYA",
            "description": "RA AR-RASYID merupakan tempat pra-sekolah yang berada di cikupa, citra raya didirikan pada tahun 2024 oleh Bu Hesti. RA AR-RASYID menekankan nilai nilai islami dalam pembelajarannya, selain RA AR-RASYID menawarkan beberapa macam pendidikan dari PAUD, Taman Kanak-anak (TK), BIMBEL, Serta BTQ, RA AR-RASYID menyediakan tempat yang aman serta nyaman untuk anak-anak.",
            "phone": "+62 877-7120-0907",
            "email": "ra.arrasyid1@gmail.com",
            "address": "Jl. Verdi Barat Blok E2 No.35, Cikupa, Citra Raya, Kabupaten Tangerang, Banten 15710",
            "mapEmbed": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.193357346931!2d106.5220004647443!3d-6.238226957657968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e4207005d9675cf%3A0x8c93b3c61c7731b5!2sTK%2FRA%20AR%20RASYID%20CITRA%20RAYA!5e0!3m2!1sen!2sid!4v1711467857167!5m2!1sen!2sid\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>",
            "createdAt": "2024-03-26 22:48:57",
            "updatedAt": "2024-03-26 15:47:43",
            "mission": [
                {
                    "id": 1,
                    "description": "Menjadikan RA AR Rasyid sebagai RA yang unggul dalam prestasi akademik dan non akademik",
                    "createdAt": "2024-03-24 13:52:22",
                    "updatedAt": "2024-03-24 13:52:22"
                },
                {
                    "id": 2,
                    "description": "Menjadikan RA AR Rasyid sebagai RA sekolah islam yang profesional dan mengdepankan akhlak yang mulia.",
                    "createdAt": "2024-03-24 13:52:22",
                    "updatedAt": "2024-03-24 13:52:22"
                },
                {
                    "id": 3,
                    "description": "Menjadikan RA AR Rasyid sebagai RA sekolah yang tepercaya di masyarakat",
                    "createdAt": "2024-03-24 13:52:22",
                    "updatedAt": "2024-03-24 13:52:22"
                }
            ],
            "vision": [
                {
                    "id": 1,
                    "description": "Menjadi sekolah islam yang unggul dan terpercaya dalam menghasilkan generasi yang menerima berakhlak mulia.",
                    "createdAt": "2024-03-24 13:52:41",
                    "updatedAt": "2024-03-24 13:52:41"
                }
            ],
            "directions": [
                {
                    "id": 1,
                    "description": "Terbentuknya pembelajaran yang berkualitas dalam pelayanan program RA sesuai dengan kondisi.",
                    "createdAt": "2024-03-24 13:53:15",
                    "updatedAt": "2024-03-24 13:53:15"
                },
                {
                    "id": 2,
                    "description": "Terselenggaranya pembelajaran yang berpusat pada anak dan sesuai dengan tahapan perkembangan anak.",
                    "createdAt": "2024-03-24 13:53:15",
                    "updatedAt": "2024-03-24 13:53:15"
                },
                {
                    "id": 3,
                    "description": "Mewujudkan lingkungan belajar yang islami dan menyenangkan.",
                    "createdAt": "2024-03-24 13:53:15",
                    "updatedAt": "2024-03-24 13:53:15"
                }
            ],
            "teachers": [
                {
                    "id": 1,
                    "name": "Istikomah, S.Pd",
                    "position": "Kepala sekolah",
                    "image": "/assets/image/teacher/teacher-1.svg",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 23:23:30",
                    "updatedAt": "2024-03-24 23:23:30"
                },
                {
                    "id": 1,
                    "name": "Rafa Afifah, S.Pd",
                    "position": "Walikelas TKA",
                    "image": "/assets/image/teacher/teacher-3.svg",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 23:23:30",
                    "updatedAt": "2024-03-24 23:23:30"
                },
                {
                    "id": 1,
                    "name": "Eva Damayanti,S.Pd",
                    "position": "Walikelas TKB",
                    "image": "/assets/image/teacher/teacher-2.svg",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 23:23:30",
                    "updatedAt": "2024-03-24 23:23:30"
                },
                {
                    "id": 1,
                    "name": "Onah Yohanah",
                    "position": "Walikelas Paud",
                    "image": "/assets/image/teacher/teacher-4.svg",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 23:23:30",
                    "updatedAt": "2024-03-24 23:23:30"
                },
                {
                    "id": 1,
                    "name": "Lulu Nurul Fadilah",
                    "position": "Guru TPQ & Bimbel",
                    "image": "/assets/image/teacher/teacher-5.svg",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 23:23:30",
                    "updatedAt": "2024-03-24 23:23:30"
                },
            ],
            "faqs": [
                {
                    "id": 1,
                    "question": "Waktu operasional TK/RA AR-RASYID Citra Raya",
                    "answer": "TK/RA Ar-Rasyid Citra Raya beroperasional dimulai dari pukul 07.30- 16.30 WIB, setiap hari Senin hingga Jumat.",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 14:05:05",
                    "updatedAt": "2024-03-24 14:05:05"
                },
                {
                    "id": 2,
                    "question": "Masa pendaftaran RA AR-Rasyid",
                    "answer":
                        "Gelombang 1 \n" +
                        "Bulan Oktober - November. Disc Rp 500.000\n" +
                        "Gelombang 2\n" +
                        "Bulan Desember - Januari,Disc Rp 250.000\n" +
                        "Gelombang 3\n" +
                        "Bulan Februari - Juni\n" +
                        "Harga & Normal",

                        //
                        // "Masa pendaftaran terbagi dalam 3 Gelombang" +
                        // "" +
                        // "Gelombang pertama berlangsung dari bulan Oktober hingga November 2023 gelombang pertama mendapatkan diskon sebesar Rp.500.000.\n\n" +
                        //  "\n" +
                        // "Gelombang kedua berlangsung dari bulan Desember 2023 hingga Januari 2024 gelombang kedua mendapatkan diskon sebesar Rp.250.000.\n\n" +
                        // "\n" +
                        // "Gelombang ketiga berlangsung dari bulan Febuari hingga Juni 2024" ,
                        "schoolId": 1,
                    "createdAt": "2024-03-24 14:05:05",
                    "updatedAt": "2024-03-24 14:05:05"
                },
                {
                    "id": 3,
                    "question": "Berapa biaya Pendaftaran RA AR-Rasyid",
                    "answer": " Biaya pendaftaran Paud Rp 1.750.000, TK Rp 2.500.000 BTQ & Bimbel Hanya Rp 200.000",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 14:05:05",
                    "updatedAt": "2024-03-24 14:05:05"
                },
                {
                    "id": 4,
                    "question": "Dalam satu kelas terdapat berapa murid?",
                    "answer": "Dalam satu kelas TK/RA Ar-Rasyid  max 15 anak dan dihandle oleh 2 orang guru\n",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 14:05:05",
                    "updatedAt": "2024-03-24 14:05:05"
                },
                {
                    "id": 5,
                    "question": "Usia minimum pendaftaran PAUD?",
                    "answer": "Untuk melakukan pendaftaran PAUD, dimulai dari usia 3 tahun",
                    "schoolId": 1,
                    "createdAt": "2024-03-24 14:05:05",
                    "updatedAt": "2024-03-24 14:05:05"
                }
            ]
        }
    };

    const tiktok = [
        {
            "url": "https://www.tiktok.com/embed/v2/7353655945509047557",
            "description": "scoihujh"
        },
        {
            "url": "https://www.tiktok.com/embed/v2/7342322558340959493",
            "description": "scoihujh"
        },
        {
            "url": "https://www.tiktok.com/embed/v2/7328594168886955270",
            "description": "scoihujh"
        }
    ]



    return (
      <>
          <div className={" pt-0 lg:pt-20 mx-auto"} style={{minWidth:"300px" ,maxWidth:"1500px"}}>
              <div className={"w-full"}>
                  <Routes>
                      <Route path={"/"} element={<Home school={school?.school} />}/>
                      <Route path={"/aktivitas"} element={<Activity />}/>
                      <Route path={"/pendaftaran"} element={<Registrastion school={school?.school} />}/>
                      <Route path={"/tentang-kami"} element={<About school={school?.school} />}/>
                      <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                  <div id={"Tiktok promosi"} className="my-10 mx-auto md:w-9/12"  style={{minWidth:"300px" ,maxWidth:"1400px"}}>
                      <div className="text-center">
                          <h2 className="fs-title  Tmoney text-center text-blue-700 fw-500">Our Social media!!</h2>
                      </div>
                      <div className="mt-5 lg:flex w-full overflow-x-auto scrollbar-hide">
                          {tiktok.map((item , index) => {
                              return(
                                  <>
                                      <TiktokCardComponent  link={item?.url}/>
                                  </>
                              )
                          })}
                      </div>
                  </div>
              </div>
          </div>
          <FooterComponent />
      </>
  );
}

export default App;
