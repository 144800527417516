
const Truncate30 =  (value) => {
    const maxLength = 30;
    if (value?.length <= maxLength){
        return value;
    } else{
        return value.substring(0 , maxLength) + "..."
    }
}

const Truncate20 = (value) => {
    const maxLength = 20;
    if (value?.length <= maxLength){
        return value;
    } else{
        return value.substring(0 , maxLength) + "..."
    }
}

const Truncate10 =  (value) => {
    const maxLength = 10;
    if (value?.length <= maxLength){
        return value;
    } else{
        return value.substring(0 , maxLength) + "..."
    }
}

export {
    Truncate10,
    Truncate20,
    Truncate30,
}