import {CardActivityComponent} from "./Card/CardActivity.Component";


export const ActivityComponent = ({name, data}) => {

    return(
        <>
            <div className={"w-full bg-white  md:pt-0 pb-4 my-0"}>
                <div className={"w-full mx-auto"}>

                    <div className={"w-full"}>
                        <div className={"w-full"}>
                            {data?.length === 0 ? (
                                <div className={"my-4 py-10"}>
                                    <div className={"py-8 text-center"}>
                                        <h2 className={"text-blue-600 Tmoney fs-28"}>Tidak ada data terkini untuk {name}</h2>
                                    </div>
                                </div>
                            ):(
                                <ul className={"flex flex-wrap"}>
                                    {data?.map((item) => {
                                        return(
                                            <li className={" my-3 w-6/12 lg:w-3/12"}>
                                                <CardActivityComponent name={item?.name} description={item?.description} image={item?.image}/>
                                            </li>
                                        )
                                    })}

                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


// export const ActivityComponent = () => {
//
//
//     const slides = [
//         {
//             image: '/assets/img/senam-2.jpg',
//             title: 'Senam pagi',
//             paragraph: 'Slide 1 Paragraph',
//         },
//
//         {
//             image: '/assets/img/class-1.jpg',
//             title: 'Pelajaran Umum',
//             paragraph: 'Slide 1 Paragraph',
//         },
//         {
//             image: '/assets/img/solat.jpg',
//             title: "Praktek Ibadah shalat",
//             paragraph: 'Slide 1 Paragraph',
//
//         },
//         {
//             image: '/assets/img/kuis.jpg',
//             title: 'Kuis',
//             paragraph: 'Slide 1 Paragraph',
//         },
//         {
//             image: '/assets/img/makan.jpg',
//             title: 'Makan siang bersama',
//             paragraph: 'Slide 1 Paragraph',
//         },
//
//         {
//             image: '/assets/img/activity-2.jpg',
//             title: 'Mandiri',
//             paragraph: 'Slide 1 Paragraph',
//         },
//
//
//     ];
//
//     return(
//         <>
//             <div className={"w-full bg-white pt-6 md:pt-0 pb-4 my-0"}>
//                 <div className={"w-full lg:w-10/12 mx-auto"}>
//                     <div className={"w-full"}>
//                         <div className={"my-4 text-center"}>
//                             <h2 className={"fs-title fw-500 text-blue-600"}>Aktivitas</h2>
//                             <p className={" fs-17 text-gray-500"}>Aktifitas yang biasanya dilakukan</p>
//                         </div>
//                     </div>
//                     <div className={"w-full"}>
//                         <div className={"w-full"}>
//                             <ul className={"flex flex-wrap"}>
//                                 {slides.map((item) => {
//                                     return(
//                                         <li className={" my-3 w-6/12 lg:w-4/12"}>
//                                             <CardActivityComponent slide={item} />
//                                         </li>
//                                     )
//                                 })}
//
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//         </>
//     )
// }