import React from "react";

export const FAQCardComponent = ({popUpDropdown , id , title  , value}) => {
    return(
        <div className={"border-b border-gray-100 pb-2  px-2"} style={{ borderRadius: "4px" }}>
            <div className={"flex w-full justify-between cursor-pointer"}>
                <div className={"w-10/12 my-auto"}>
                    <p className={"fs-20 fw-500 text-blue-600 my-auto"}>{title}</p>
                </div>
                <div className={"w-2/12 justify-end items-end"}>
                    <div className="my-auto justify-end items-end inset-y-0 right-0 flex px-2 pointer-events-none">
                        <svg className="h-4 w-4 my-auto text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M4.293 5.293a1 1 0 011.414 0L10 9.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            {popUpDropdown[id] && (

                <div className={"py-2"}>
                    <p className={"fs-16 text-gray-500"}>{value}</p>
                </div>
            )}
        </div>
    )
}