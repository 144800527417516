import React, {useState} from "react";

export const BannerAboutComponent = ({name , description , address , contact , email , externalImage}) => {

    const [image , setImage] =  useState(null)

    const handleChangeImage = (imageUrl) => {
        setImage(imageUrl);
    };

    const [isDropdown, setIsDropdown] = useState(false);

    const handleIsDropdown = () => {
        setIsDropdown(!isDropdown);
    };

    return(
        <>
            <div className="w-full pt-16 lg:pt-10" style={{ minWidth:"300px", background:"#ffffff"}}>
                <div className="w-11/12 md:w-full lg:w-10/12  mx-auto py-10 radius-8 " style={{ background:"#ffffff"}}>
                    <div className="w-full lg:w-11/12 md:w-10/12 mx-auto">
                        <div className={"w-full gap-4 block md:flex"}>
                            <div className={"lg:w-6/12 w-full"}>
                                <div className={"h-banner-img-about border-gray-50 border shadow radius-4 w-full"}>
                                    <img className={"object-cover w-full radius-4 h-full"} src={image ? `${image}` : "/assets/img/logo.jpg"}/>
                                </div>
                            </div>
                            <div className={"lg:w-6/12 my-auto w-full"}>
                                <div className="overflow-x-auto   my-auto scrollbar-hide">
                                    <div className="md:block  my-4 flex-no-wrap scrollbar-hide ">
                                        <div className={"w-full flex gap-3"}>
                                            <div className={" w-full  h-ext-img"  }>
                                                <img
                                                    onClick={() => handleChangeImage("/assets/img/school.jpg")}
                                                    className="w-full radius-4 h-full object-cover cursor-pointer"
                                                    src={ "/assets/img/school.jpg"}
                                                    alt="School"
                                                />
                                            </div>
                                            <div className={" w-full  h-ext-img"  }>
                                                <img
                                                    onClick={() => handleChangeImage("/assets/img/outdoor-class.jpg")}
                                                    className="w-full radius-4 h-full object-cover cursor-pointer"
                                                    src={ "/assets/img/outdoor-class.jpg"}
                                                    alt="School"
                                                />
                                            </div>
                                        </div>
                                        <div className={"w-full flex gap-3"}>
                                            <div className={" my-2 w-full h-ext-img"  }>
                                                <img
                                                    onClick={() => handleChangeImage("/assets/img/class-1.jpg")}
                                                    className="w-full radius-4 h-full object-cover cursor-pointer"
                                                    src={ "/assets/img/class-1.jpg"}
                                                    alt="School"
                                                />
                                            </div>
                                            <div className={" w-full my-2 h-ext-img"  }>
                                                <img
                                                    onClick={() => handleChangeImage("/assets/img/class-bg.jpg")}
                                                    className="w-full radius-4 h-full object-cover cursor-pointer"
                                                    src={ "/assets/img/class-bg.jpg"}
                                                    alt="School"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <article className="w-full text-left ">
                            <div className={" py-3 border-t border-gray-200 "}>
                                <div className="w-full mx-auto">
                                    <h1 onClick={handleIsDropdown} className="w-full righteous md:pt-6 pt-5 pb-0 text-blue-600 fs-28" >Sejarah singkat {name}</h1>
                                    <div className={`my-2 ${isDropdown ? 'open' : 'closed'}`}>
                                        <p className="fs-17 text-gray-500">
                                            Ar Rasyid didirikan pada bulan Oktober 2023 mulai dari PAUD, TK/RA sebagai bentuk kepedulian kami terhadap anak usia dini yang saat ini banyak mengalami dampak negatif dr seringnya bermain gadget (Hp) seperti speech delay, sering tantrum, kurang konsentrasi,dsb.
                                            <br/><br/>
                                            Oleh sebab itu Ar Rasyid hadir ditengah-tengah masyarakat khususnya wilayah Citra Raya Cikupa dan sekitarnya Sebagai Solusi untuk orangtua yang bingung mencari Sekolah anak usia dini yg berbasis Islami, memprioritaskan perkembangan nilai agama dan moral, perkembangan fisik-motorik, kognitif, bahasa, sosial emosional, dan perkembangan seni kreativitas anak.
                                            <br/><br/>
                                            Disamping itu, TK/RA AR-RASYID juga merupakan sekolah dengan Harga yang sangat Terjangkau namun Tetap memprioritaskan Kualitas.
                                        </p>
                                    </div>
                                    <p className="poppins text-gray-500 fs-17 my-3" >
                                        <b>Alamat</b> : {address}
                                    </p>
                                    <p className="poppins text-gray-500 fs-17 my-1" >
                                        <b>Kontak</b> : {contact}
                                    </p>
                                    <p className="poppins text-gray-500 fs-17 my-1" >
                                        <b>Email</b> : {email}
                                    </p>
                                </div>
                            </div>

                        </article>

                    </div>
                </div>
            </div>


        </>
    )
}