import React, {useEffect, useState} from "react";

export const SlideComponent = ({ slides }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        } , 5000);

        return () => clearInterval(intervalId)
    }, [currentSlide])

    return (
        <div className="flex w-full relative justify-center">
            <div className={"flex w-full xl:w-10/12 lg:w-11/12 relative"}>
                <div className={"h-img-banner-prev hidden md:block radius-8 absolute  top-20 bottom-0 left-0 bg-white "}>
                    <div className={"h-img-banner-prev bg-white px-1 radius-8 py-1 relative justify-center mx-auto items-center gap-4"}>
                        <img src={slides[currentSlide === 0 ? slides.length - 1 : currentSlide - 1].image} className={"w-full h-full mx-auto  object-cover radius-8"} alt={`Slide`} />
                        <div className={"shadow-n radius-8"}></div>
                    </div>
                </div>
                <div className="items-center z-40 w-full lg:w-10/12 mx-auto">
                    <div className={"h-img-banner mb-0 md:mb-4 md:bg-white md:px-1 radius-8 py-0 relative justify-center mx-auto items-center gap-4"}>
                        <img src={slides[currentSlide].image} className={"w-full h-full mx-auto  object-cover radius-8 transition-opacity duration-500"} alt={`Slide`} />
                        <div className={"shadow-n radius-8"}></div>
                    </div>
                    <div className={"py-3 bg-white"}>
                        <div className={"text-center  Tmoney text-white"}>
                            <h2 className={"fs-28 text-blue-600 fw-700"}>{slides[currentSlide].title}</h2>
                            <h2 className={"fs-17 text-blue-600"}>{slides[currentSlide].paragraph}</h2>
                        </div>
                    </div>
                </div>
                <div className={"h-img-banner-prev radius-8 hidden md:block absolute my-auto top-0 bottom-20 right-0 bg-white "}>
                    <div className={"h-img-banner-prev bg-white px-1 radius-8 py-1 relative justify-center mx-auto items-center gap-4"}>
                        <img src={slides[currentSlide === slides.length - 1 ? 0 : currentSlide + 1].image} className={"w-full h-full mx-auto  object-cover radius-8"} alt={`Slide`} />
                        <div className={"shadow-n radius-8"}></div>
                    </div>
                </div>
            </div>
            <div className={"absolute w-full top-0 bottom-32 items-center z-40  flex justify-center"}>
                    <button className={"bg-white  text-blue-600 cursor-pointer  z-40 "} onClick={prevSlide} >
                            <div className="prev bg-blue-600 hover:bg-blue-700 rounded   pt-2 pb-2  px-2 text-blue-600 cursor-pointer  radius-4 items-center text-center z-40   my-auto justify-center absolute left-0">
                            <img src={"/assets/img/arrow-left.svg"} />
                        </div>
                    </button>
                    <button className={"bg-white  my-auto text-blue-600 cursor-pointer z-40   "} onClick={nextSlide} >
                            <div className="next bg-blue-600 hover:bg-blue-700 rounded  pt-2 pb-2  px-2 text-blue-600 cursor-pointer  radius-4 items-center text-center z-40   my-auto justify-center absolute right-0">
                            <img src={"/assets/img/arrow-right.svg"} />
                            </div>
                    </button>
            </div>
        </div>
    );
};
